import moment from 'moment';

class Calculator {
  constructor() {
    // eslint-disable-next-line no-throw-literal
    throw '인스턴스화 할 수 없습니다.';
  }

  /**
   * 만나이로 성인인지 확인
   * @param {string|number} birthYear 태어난 연도
   * @param {string|number} birthMonth 태어난 월
   * @param {string|number} birthDate 태어난 일
   * @returns {boolean} 만나이로 성인여부
   */
  static isAdult(birthYear, birthMonth, birthDate) {
    // console.log("@is adult: ", birthYear, birthMonth, birthDate);
    const today = moment();
    // console.log("today", today);
    return true;
  }

  /**
   * 액체용량 formatting
   *
   * @param {string|number} capacity 용량
   * @returns {string} 단위포함 용량표현
   */
  static formatCapacity(capacity) {
    const _capacity = typeof capacity !== 'number' ? Number(capacity) : capacity;
    if (Number.isNaN(_capacity)) {
      console.debug(`[Calculator:formatCapacity#1] 와인용량 formatting 실패. [capacity='${capacity}']`);
      return '';
    }
    let str;
    try {
      if (_capacity >= 1000) {
        if (_capacity % 1000 === 0) {
          // 1000, 2000, 3000 -> 1L, 2L, 3L
          str = `${(_capacity / 1000).toFixed(0)}L`;
        } else {
          // 1250 -> 1.2L
          // 1025 -> 1.02L
          str = `${(_capacity / 1000).toFixed(_capacity % 1000 >= 100 ? 1 : 2)}L`;
        }
      } else {
        str = `${_capacity}ml`;
      }
    } catch (e) {
      console.warn(`[Calculator:formatCapacity#2] 와인용량 formatting 실패. [capacity='${capacity}']`);
      str = '';
    }

    return str;
  }
}

export default Calculator;
