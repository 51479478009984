import React from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { setGeolocationState, setPosition } from 'store/slices/geolocation';

export const useBrowserLocation = (): {
  initialized: boolean;
  state?: 'granted' | 'denied' | 'unavailable' | string;
  coords?: { latitude: number; longitude: number };
  requestCurrentLocation: () => Promise<{ latitude: number; longitude: number }>;
  refreshCurrentLocation: () => Promise<void>;
} => {
  const dispatch = useAppDispatch();

  const { geolocation }: any = useAppSelector((state) => {
    return {
      geolocation: state['geolocation']
    };
  });

  const requestCurrentLocation = async (): Promise<{ latitude: number; longitude: number }> => {
    return new Promise<{ latitude: number; longitude: number }>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log('---> position', position);
          const { latitude, longitude } = position.coords;
          dispatch(setPosition({ latitude, longitude }));
          resolve({ latitude, longitude });
        },
        (error: GeolocationPositionError) => {
          const { code, PERMISSION_DENIED } = error;
          if (code === PERMISSION_DENIED) {
            dispatch(setGeolocationState({ state: 'denied' }));
          } else {
            console.error('#error@requestCurrentLocation: ', error);
          }

          reject(error);
        }
      );
    });
  };

  /** 사용자 현위치 갱신 */
  const refreshCurrentLocation = React.useCallback(async () => {
    if (geolocation.state === 'granted') {
      await requestCurrentLocation();
    } else {
      console.warn('현재위치 갱신할 수 없음.', geolocation);
    }
  }, [geolocation.state]);

  return { ...geolocation, requestCurrentLocation, refreshCurrentLocation };
};
