import { styled } from '@mui/material';

const HiddenSpan = styled('span')({
  position: 'absolute',
  clip: 'rect(0 0 0 0)',
  width: '1px',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  '&::before': {
    display: 'block',
    width: 0,
    height: 0,
    content: "'\xa0'",
    fontSize: 0
  }
});

export default HiddenSpan;
