import React from 'react';
import { Typography } from '@mui/material';

type CardTitleProps = { primary: string; size?: 'small' | 'medium' };

const CardTitle: React.FC<CardTitleProps> = ({ primary, size = 'medium' }: CardTitleProps) => {
  const { fontSize, lineHeight } = React.useMemo(() => {
    if (size === 'small') return { fontSize: '16px', lineHeight: '20px' };
    return { fontSize: '22px', lineHeight: '24px' };
  }, [size]);

  return (
    <Typography component="h6" fontSize={fontSize} lineHeight={lineHeight} fontWeight="bold" letterSpacing="-0.5px">
      {primary}
    </Typography>
  );
};

export default CardTitle;
