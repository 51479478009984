import React from 'react';
import { useRouter } from 'next/router';

import PdataImage from 'components/pdata/PdataImage';
import HiddenSpan from 'components/HiddenSpan';
import { FlexRowCenter } from 'components/flex-box';

import { CardTitle } from 'pages-sections/home';

import { Box, ButtonBase, Card, CardContent, Divider, Pagination, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

type MainCurationCardProps = { curation: { id: string; pdatas: any[] } & any };

const MainCurationCard: React.FC<MainCurationCardProps> = ({ curation }: MainCurationCardProps) => {
  // const [page, setPage] = React.useState<number>(1);
  const pageSize = 4;
  const router = useRouter();

  const initNumber = React.useMemo(() => {
    const value = window.sessionStorage.getItem(`home-curation-${curation.id}-page`);
    const page = value !== null ? parseFloat(value) : 1;
    return page > Math.ceil(curation.pdatas.length / pageSize) ? 1 : page;
  }, []);

  const [currPage, setCurrPage] = React.useState<number>(initNumber);

  const handlePage = (page: number) => {
    setCurrPage(page);
    window.sessionStorage.setItem(`home-curation-${curation.id}-page`, page.toString());
  };

  React.useEffect(() => {
    // 페이지 새로고침할 때, currPage 초기화
    const handleRouteChange = () => {
      window.sessionStorage.removeItem(`home-curation-${curation.id}-page`);
    };

    // 초기화용 이벤트 등록
    window.addEventListener('beforeunload', handleRouteChange);

    return () => {
      window.removeEventListener('beforeunload', handleRouteChange);
    };
  }, []);

  // render
  return (
    <Card>
      <CardContent>
        <Typography fontSize="12px" lineHeight="14px" color="#707078" letterSpacing="-0.3px" sx={{ mb: 0.5 }}>
          1KMWINE 추천
        </Typography>
        <CardTitle size="small" primary={curation.title} />
        <Divider sx={{ mt: 1.75 }} />
        <Box mt={2}>
          <Grid container spacing={2}>
            {curation.pdatas.slice((currPage - 1) * pageSize, (currPage - 1) * pageSize + pageSize).map((pdata) => {
              return (
                <Grid xs={6} key={`main-curation-${curation.id}-pdata-${pdata._id}`}>
                  <Box position="relative">
                    <PdataImage id={pdata._id} width="100%" height="140px" alt={pdata.name.ko} py={2} />
                    <Box mt={0.5}>
                      <Typography fontWeight={600}>{pdata.price_min.toLocaleString()}원 ~</Typography>
                    </Box>
                    <Typography
                      fontSize="14px"
                      lineHeight="20px"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        wordBreak: 'keep-all'
                      }}
                      letterSpacing="-0.3px"
                    >
                      {pdata.name.ko}
                    </Typography>
                    <ButtonBase
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                      }}
                      onClick={() => {
                        router.push(`/product/${pdata.code}`);
                      }}
                    >
                      <HiddenSpan>상품보기</HiddenSpan>
                    </ButtonBase>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {curation.pdatas.length > pageSize ? (
          <FlexRowCenter mt={2.5}>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={Math.ceil(curation.pdatas.length / pageSize)}
              page={currPage}
              onChange={(e, page) => {
                handlePage(page);
              }}
            />
          </FlexRowCenter>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default MainCurationCard;
