import React from 'react';

// import project
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import mui
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

// assets
import vendorDefaultImg from 'assets/images/default_vendor.png';

const useStyles = makeStyles((theme) => ({
  frame: {
    border: 'none',
    display: 'inline-block',
    overflow: 'hidden',
    padding: '0',
    position: 'relative'
  }
}));

type VendorImageProps = {
  width: string | number;
  height: string | number;
  src?: string;
  borderRadius?: string | number;
  wrapperProps?: any;
  children?: any;
  alt?: string;
};

/**
 * 와인샵 이미지 V2
 *
 * @authors 조현권<hkcho@wineone.io>
 */
function VendorImage({
  children,
  width,
  height,
  src: srcProp = vendorDefaultImg.src,
  wrapperProps = {},
  borderRadius = '10px',
  ...others
}: VendorImageProps) {
  const classes = useStyles();

  const [src, setSrc] = React.useState<string>(srcProp);
  React.useLayoutEffect(() => {
    setSrc(srcProp);
  }, [srcProp]);

  // 이미지 조회 실패시 기본 상품 이미지로 교체
  const onError = React.useCallback(() => {
    setSrc(vendorDefaultImg.src); // Replace to default wine product image.
  }, []);

  const imgComponent = React.useMemo(
    () =>
      src ? (
        <LazyLoadImage
          wrapperClassName="vendor-image-wrapper"
          src={src}
          alt={others.alt ?? '와인샵 이미지'}
          placeholder={<CircularProgress size={8} />}
          width="100%"
          height="100%"
          wrapperProps={wrapperProps}
          draggable={false}
          onError={onError}
          style={{ borderRadius }}
        />
      ) : (
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="primary" size={8} />
        </Box>
      ),
    [width, height, src]
  );

  return (
    <Box
      className={classes.frame}
      width={width}
      minWidth={width}
      height={height}
      minHeight={height}
      borderRadius={borderRadius}
      draggable={false}
      sx={{ overflow: 'hidden' }}
    >
      {imgComponent}
      {children}
    </Box>
  );
}

// export
export default VendorImage;
