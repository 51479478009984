/* *********************************************************************************************************************
 * Elstic Search
 *  - https://github.com/elastic/app-search-javascript
 ******************************************************************************************************************** */

import * as ElasticAppSearch from '@elastic/app-search-javascript';

/**
 * Elastic App Search client 생성
 * @param engineName 검색대상 엔진이름
 */
export const createElasticAppSearchClient = (engineName: string) =>
  ElasticAppSearch.createClient({
    searchKey: process.env.NEXT_PUBLIC_ELASTC_SRCH_API_KEY as string,
    endpointBase: process.env.NEXT_PUBLIC_ELASTIC_SRCH_ENDPOINT_BASE as string,
    cacheResponses: false,
    engineName
  });
